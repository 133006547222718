export const APP_NAME = "SpactureAi";
export const PERSIST_STORE_NAME = "admin";
export const REDIRECT_URL_KEY = "redirectUrl";
import dayjs from "dayjs";


export const RANGE_SCHEMA = { 
    "week" : () => ({ startDate: dayjs().subtract(7, "day").toDate(), endDate: new Date() }),
    "month" : () => ({ startDate: dayjs().subtract(30, "day").toDate(), endDate: new Date() }), 
    "three-month" : () => ({ startDate: dayjs().subtract(90, "day").toDate(), endDate: new Date() }),
    "year" : () => ({ startDate: dayjs().subtract(365, "day").toDate(), endDate: new Date() }) 

};
