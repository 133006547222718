import { RANGE_SCHEMA } from "constants/app.constant";
import dayjs from "dayjs";
import React, { createContext, useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
// import * as infoData from "../assets/info.json";
import { useCompanyData, useStoreData } from "helper";

const FilterContext = createContext(null);

export const useFilter = () => {
  return useContext(FilterContext);
};
// const defaultOptions = {
//   loop: true,
//   autoplay: true, 
//   animationData: infoData,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice"
//   }
// };
export const FilterProvider = ({ children }) => {
    const [selectedCompany, setSelectedCompany] = useState();
    const [hidden, setHidden] = useState("hidden");

    const { data: companyData } = useCompanyData();

    const [startDate, setStartDate] = useState(dayjs().subtract(7, "day").toDate());
    const [endDate, setEndDate] = useState(dayjs().subtract(1, "day").toDate());
    const [timeRange, setTimeRange] = useState(["week"]);
    const [selectedStore, setSelectedStore] = useState();

    const { data: storeData, mutate:mutateStr } = useStoreData(selectedCompany);

    const handleRange = () => {
      const { startDate:stD, endDate:endD } = RANGE_SCHEMA[timeRange?.[0]]();
      setStartDate(stD);
      setEndDate(endD);
    };

    useEffect(() => {
        handleRange();
    }, [timeRange]);

    useEffect(() => {
        mutateStr();
    }, [selectedCompany]);


    useEffect(() => {
      companyData && !selectedCompany && 
      setSelectedCompany({ value:companyData?.result?.[0]?.id, label:companyData?.result?.[0]?.name });
    }, [companyData]);

    useEffect(() => {
      storeData && setSelectedStore({ value:storeData?.result?.[0]?.id, label:storeData?.result?.[0]?.name });
    }, [storeData]);

  const value = {
    startDate,
    endDate,
    selectedStore,
    selectedCompany,
    timeRange,
    setStartDate,
    hidden,
    setHidden,
    companyData,
    storeData,
    setSelectedCompany,
    setSelectedStore,
    setEndDate,
    setTimeRange
  };

  return (
      <FilterContext.Provider value={ value }>
          { /* <div style={ { width: "100%", position:"absolute", zIndex:999 } } className={ `absolute h-screen flex items-center justify-center ${hidden}` }>
              <Lottie options={ defaultOptions }
                  height={ 400 }
                  width={ 400 }/>
          </div> */ }
          { children }
      </FilterContext.Provider>
  );
};
