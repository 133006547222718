export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const sizePerPageList = [
    { text: "3", value: 3 },
    { text: "5", value: 5 },
    { text: "10", value: 10 }
];
export const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
    sortField: "id",
    sortOrder: "desc",
    filter: { active: 1 }
};
export const statusOptions = [
    { value: "", label: "All", color: "bg-gray-500" },
    { value: "1", label: "Active", color: "bg-emerald-500" },
    { value: "0", label: "Deactive",  color: "bg-red-500"  }
];
export const sizes = [
    { label: "3", value: 3 },
    { label: "5", value: 5 },
    { label: "10", value: 10 }
];
export const genders = [
    { label: "Male", value: 0 },
    { label: "Female", value: 1 }
];
export const infoFormItemLayout = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 14
    }
};
export const statusColor = {
    "false": "bg-red-500",
    "true": "bg-emerald-500"
};


export const statusText = {
    "false": "Deactive [Deleted]",
    "true": "Active"
};
export const isLiveText = {
    "false": "Not Alive",
    "true": "Live"
};

export const dataStatus = {
    "true": {
        label: "Active",
        class: "bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100"
    },
    "false": {
        label: "Deactive [Deleted]",
        class: "text-red-500 bg-red-100 dark:text-red-100 dark:bg-red-500/20"
    }
};

export const historyLabels = {
    "device_move_history": {
        label: "Cihaz Değiştirme İşlemi",
        class: "bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100"
    },
    "sim_card_move_history": {
        label: "Sim Kart Değiştirme İşlemi",
        class: "bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100"
    },
    "staff_move_history": {
        label: "Personel Değişim İşlemi",
        class: "bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100"
    }
   
};


export const detectionTypeColor = {
    "face_detection": {
        dotClass: "bg-emerald-500",
        textClass: "text-emerald-500",
        text: "Face Detection"
    },
    "watchlist_face_detection": {
        dotClass: "bg-amber-500",
        textClass: "text-amber-500",
        text: "Watchlist Alert"
    },
    "shoplifting": {  
        dotClass: "bg-red-500", 
        textClass: "text-red-500",
        text: "Suspicious Activity",
        types_of_shoplifting: {
            "eating": {
                dotClass: "bg-yellow-400", 
                textClass: "text-red-500",
                text: "Eating"
            },
            "suspicious": {
                dotClass: "bg-orange-500", 
                textClass: "text-red-500",
                text: "Suspicious"
            },
            "very_suspicious": {
                dotClass: "bg-red-700", 
                textClass: "text-red-500",
                text: "Very Suspicious"
            }
        }
     }
};
