import axios from "axios";
import React, { Suspense, lazy, memo, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { onSignInSuccess } from "store/auth/sessionSlice";
import { setUser } from "store/auth/userSlice";
import useAuth from "utils/hooks/useAuth";
import useQuery from "utils/hooks/useQuery";
import { Loading } from "components/shared";
import { FilterProvider } from "context/FilterProvider";

const Fallback = () => (
    <div className="flex flex-auto flex-col h-[100vh]">
        <Loading customLoader={ <PropagateLoader /> } loading={ true } />
    </div>
);
const AppLayout = lazy(() => import("./Layout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const Layout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { authenticated, signOut } = useAuth();
    const query = useQuery();
    const queryToken = useMemo(() => query.get("token"), [query]);
    const fromElectron = useMemo(() => query.get("electron") ?? false, [query]);

    const handleToken = async () => {
        if (!queryToken) return;
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/authentication/back-office/me`, {}, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${queryToken}`
                }
            });
            const { data: { result: { user, tokens }, success } } = response;
            if (!success) {
                signOut();
            }
            dispatch(onSignInSuccess(tokens));
            dispatch(
                setUser({ authority: user?.roles?.map(el => el?.slug), ...user, fromQuery: true, fromElectron })
            );
            navigate("/dashboard");
        } catch (error) {
            signOut();
        }

    };

    useEffect(() => {
        handleToken();
    }, [queryToken]);

    const appLayout = useMemo(() => {
        if(queryToken) {
            return (
                <Fallback />
            );
        }
        if (authenticated) {
            return <FilterProvider><AppLayout /></FilterProvider>;
        }
        return <AuthLayout />;
    }, [authenticated, queryToken]);
    return (
        <Suspense fallback={ <Fallback /> }>
            { appLayout }
        </Suspense>
    );
};

export default memo(Layout);
